<template>
  <div>
    <StudentFAQ />
    <div>
      <h2>How do I purchase an aviation headset?</h2>
      <p>
        An aviation headset is one of those long-term purchases that will serve you well
        in your flight training. Unfortunately, aviation headsets can be expensive. However,
        when you finally make the decision, it will significantly improve your flight training
        experience. You'll be able to hear your instructor and the control tower better and clearer,
        and there will be significantly reduced external noise when in-flight.
      </p>
      <p>
        All headsets can be generally divided into two types:
      </p>
      <div class="list-container">
        <ol class="steps-list">
          <li>Active Noise Reduction (ANR)</li>
          <li>Passive Noise Reduction (PNR)</li>
        </ol>
      </div>
      <p>
        ANR headsets generally cost more, but are recommended for flight training, due to the
        reduction in external noise. ANR headsets are typically battery-powered for electronics that
        cancel out external cockpit noise i.e. you simply won't be able to hear noise, such as the engine
        or airflow against the aircraft. Both ANR and PNR headsets use a physical seal to help reduce
        external noise, but PNR headsets do not include an additional electronic module for noise-cancelling.
      </p>
      <p>
        Once you have taken the plunge, it is <b>very important</b> that you read the manual for
        your headset, so that if you find yourself with a failed headset, you are able to restore
        comms! Wishing you'd read the manual back on the ground, while in-flight with a comm failure,
        is not the best feeling. Also familiarise yourself with the communication failure procedures
        in the VFR Guide and the ERSA for Archerfield.
      </p>
      <p>
        Many of our staff and students use the Lightspeed Zulu 3 headset or the Bose A20 headset.
        There are other models and brands, which will work fine, however, steer clear of the cheaper
        unknown brands as they tend to cause increased radio interference in our aircraft.
      </p>
      <p>
        Headsets have various plug types. For example, helicopter plugs are different to fixed-wing
        aeroplane plugs (sometimes called <quote>"GA plugs"</quote>). The correct plug configuration for aeroplane
        is made up of two separate plugs; one for speaker, one for microphone, with the following specifications:
      </p>
      <div class="list-container">
        <ul class="steps-list">
          <li>
            <p>
              Speaker
              <ul>
                <li>PJ-055</li>
                <li>M642/4-1</li>
                <li>0.25 inch/6.35mm</li>
              </ul>
            </p>
          </li>
          <li>
            <p>
              Microphone
              <ul>
                <li>PJ-068</li>
                <li>M642/5-1</li>
                <li>0.206 inch/5.23mm</li>
              </ul>
            </p>
          </li>
        </ul>
      </div>
      <p>
        Any aviation headset vendor should be able to easily assist you with these details.
      </p>
    </div>
  </div>
</template>

<script>
import StudentFAQ from '@/components/StudentFAQ.vue'

export default {
  components: {
    StudentFAQ
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_presets.scss';

.list-container {
  display: inline-block;
}

.steps-list {
  list-style-type: square;
  text-align: left;
}
</style>
